import "./Blog.css";
import "./BlogDetails.css";
import React, { useContext } from "react";
import { EntryList } from "./EntryList";

export const BlogDetails = (props) => {
  return (
    <>
      <header className="masthead" style={{ paddingTop: 0, paddingBottom: 0 }}>
        <section className="parallax-container">
          <div className="site-heading">
            <h1>Pieces of my mind</h1>
            <span className="subheading">by a ruthless nihilist</span>
          </div>
        </section>
      </header>
      <section className="buffer">
        <p className="mt-0 mb-0 container droid-arabic-kufi text-right text-white py-5">
          أنكتب للغير أم لأنفسنا
          <br />
          <br />
          أنكتب للتوثيق أم لإحداث تغيير
          <br />
          أنكتب وفاء للماضي أم أملًا في المستقبل
          <br />
          أنكتب عِرفانًا أم طمعاً
          <br />
          لماذا نكتب
          <br />
          ونشغل أنفسنا بالتسجيل
          <br />
          بتدوين التاريخ
          <br />
          لماذا نهتم بما سبق
          <br />
          ولماذا أحيانًا نهتم بإيصال المعنى
          <br />
          للآخر
          <br />
          لماذا نهتم بترك أثر
          <br />
          ولماذا أكتب أنا؟
          <br />
          أكتب لأوّثق الأفكار
          <br />
          وتارة أكتب رجاءً
          <br />
          وأخرى اعتذارًا
          <br />
          أكتب وفاء لنفسي
          <br />
          وأكتب طمعًا في أن يراني الآخر
          <br />
          وأكتب وضاعة لا أحتمل قولها
        </p>
      </section>
    </>
  );
};
