import React, { useContext } from "react";
import { EntryContext } from "./EntryProvider";
import * as moment from "moment";
import { toast } from "react-toastify";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import "./Entry.css";

export const Entry = ({ entry, moods }) => {
  const mood = moods.find((m) => m.id === entry.moodId);
  const { deleteEntry, getEntryById } = useContext(EntryContext);
  const handleCopyText = (entry) => {
    navigator.clipboard.writeText("Copy this text to clipboard");
    toast.dark(entry.title + " copied");
  };
  return (
    <section className="entry">
      <div dir="auto" className="post-preview">
        <div>
          <h2 className="post-title  text-capitalize droid-arabic-kufi d-flex align-items-center">
            <Link className="text-white" to={"/blogdetails/" + entry.id}>
              {entry.title != null && entry.title.length > 0 ? (
                <span>{entry.title}</span>
              ) : (
                <small className="text-italic no-title">No title</small>
              )}
            </Link>

            <button
              className="btn btn-link text-white"
              onClick={() => handleCopyText(entry)}
            >
              <i className="fas fa-copy"></i>
            </button>
          </h2>
          <p className="post-subtitle text-white droid-arabic-kufi">
            {entry.content}
          </p>
        </div>
        <p className="post-meta">
          <a style={{ fontSize: 10 }} href="#!">
            Published on
          </a>

          {moment(entry.diaryDate).format("dddd, MMMM Do YYYY")}
        </p>
      </div>

      <hr className="my-4" />
      <div className="d-none">
        <button
          className="btn btn-danger"
          onClick={() => {
            deleteEntry(entry);
          }}
        >
          Delete
        </button>
        <a
          target="_blank"
          href={
            "http://localhost:1337/admin/plugins/content-manager/collectionType/application::shredden-pieces.shredden-pieces/" +
            entry.id
          }
          className="btn"
          onClick={() => {
            getEntryById(entry.id);
          }}
        >
          <i className="fa fa-pencil" aria-hidden="true"></i>
          Edit
        </a>
      </div>
    </section>
  );
};
