import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { EntryProvider } from "./components/EntryProvider";
import { EntryForm } from "./components/EntryForm";
import { Blog } from "./components/Blog";
import { Gallery } from "./components/Gallery";

import { MoodProvider } from "./components/mood/MoodProvider";
import { BlogDetails } from "./components/BlogDetails";

export const DailyJournal = () => {
  return (
    <EntryProvider>
      <MoodProvider>
        <Routes>
          <Fragment>
            <Route exact path="/" element={<Blog />} />
            <Route exact path="/gallery" element={<Gallery />} />

            <Route path="/entryform" element={<EntryForm />} />
            <Route path="/blogdetails/:id" element={<BlogDetails />} />
          </Fragment>
        </Routes>
      </MoodProvider>
    </EntryProvider>
  );
};
