import "./Gallery.css";
import "./BlogDetails.css";
import React, { useContext } from "react";
import { EntryList } from "./EntryList";
function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const images = shuffle([
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/DSC04670.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/DSC04669.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/DSC04706.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1031.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1051.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1113.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1250.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1252.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1338.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1366.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1521.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1531.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1537.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_1550.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2029.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2042.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2043.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2070.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2081.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2084.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2101.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2147.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2203.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2210.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2230.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2264.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2273.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2274.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2322.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2417.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2428.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2461.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2522.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2561.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2566.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2602.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2607.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2608.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2621.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2623.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2626.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2628.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2639.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2643.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2644.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2652.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2666.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2679.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2682.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2695.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2698.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2703.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2727.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2775.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2819-2.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2819.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2889.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2896.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2899.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_2967.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3034.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3157-Edit.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3158.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3159.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3160.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3265-2.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3265.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3342.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3346.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3450.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3453.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3528.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3532.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3533.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3536.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3563.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3570.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3571.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3578.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3608.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3610.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3659.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3710.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3712.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3715.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3732.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3746.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3748.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3749.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_3983.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4023.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4041.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4085.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4113.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4151.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4164.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4168.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4173.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4183.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4227.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4229.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4233.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4243.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4333.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4338.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4342.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4389.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4403-2.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4403.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4486-2.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4486.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4517.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4536.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4538.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4561.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4640.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4672.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4681-2.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4681.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4698.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4705.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4720.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4728.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_4729.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_7408.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_8971.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_8976.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_9195.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_9201.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_9221.jpg",
  "https://mohab-photopgraphy-edits.s3.eu-central-1.amazonaws.com/IMG_9365.jpg"
]);

const primeNumbers = shuffle([3, 5, 7]);

const getIsDouble = (index) => {
  return index % primeNumbers[0] == 0;
};
export const Gallery = (props) => {
  return (
    <>
      <header className="masthead" style={{ paddingTop: 0, paddingBottom: 0 }}>
        <section className="parallax-container">
          <div className="site-heading">
            <h1>Camera shatters</h1>
            <span className="subheading">by a ruthless nihilist</span>
          </div>
        </section>
      </header>
      <ul className="img-list">
        {images.map((image, index) => {
          return (
            <li key={index} className={getIsDouble(index) ? "double" : ""}>
              <img src={image} />
            </li>
          );
        })}

        <li className="double d-none">
          <span className="img-placeholder">- placeholder -</span>
        </li>
      </ul>
    </>
  );
};
