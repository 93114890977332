import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { DailyJournal } from "./DailyJournal";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";

const sun =
  "https://www.uplooder.net/img/image/55/7aa9993fc291bc170abea048589896cf/sun.svg";
const moon =
  "https://www.uplooder.net/img/image/2/addf703a24a12d030968858e0879b11e/moon.svg";

const toggleGrayScale = () => {
  const body = document.querySelector("body");
  const themeIcon = document.querySelector("#theme-icon");

  if (body.classList.contains("dark")) {
    body.classList.remove("dark");
    themeIcon.src = moon;
  } else {
    body.classList.add("dark");
    themeIcon.src = sun;
  }
};

ReactDOM.render(
  <React.StrictMode>
    <nav className="navbar navbar-expand-lg navbar-light" id="mainNav">
      <div className="container px-4 px-lg-5">
        <a className="navbar-brand" href="/">
          Shredden Pieces
        </a>

        <img
          onClick={() => toggleGrayScale()}
          id="theme-icon"
          src="https://www.uplooder.net/img/image/2/addf703a24a12d030968858e0879b11e/moon.svg"
        />

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <i className="fas fa-bars"></i>
        </button>
        <div className="collapse d-none navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto py-4 py-lg-0">
            <li className="nav-item">
              <a className="nav-link px-lg-3 py-3 py-lg-4" href="/gallery">
                Photography
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link px-lg-3 py-3 py-lg-4" href="/">
                Diary
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <Router>
      <DailyJournal />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
