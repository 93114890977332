import React, { useContext, useEffect, useState } from "react";
import { EntryContext } from "./EntryProvider";
import { Entry } from "./Entry";
import { MoodContext } from "./mood/MoodProvider";
import * as axios from "axios";
import "./EntryList.css";

const years = [2023, 2022, 2021, 2020, 2019];
const isArabic = (text) => {
  const arabic = /[\u0600-\u06FF]/;

  return arabic.test(text);
};

export const EntryList = () => {
  const { entries, searchEntries } = useContext(EntryContext);
  const { moods, getMoods } = useContext(MoodContext);
  const [filteredEntries, setEntries] = useState([]);
  const [searchTerm, setTerm] = useState("");
  const [count, setCount] = useState(1);
  const [moodSelected, setMoodSelected] = useState("");
  const [selectedPage, setPage] = useState(1);
  const [pagesArr, setPagesArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLang, setLang] = useState("all");
  const [selectedYear, setSelectedYear] = useState(null);

  function PaginatedDisplay({ pageCount, startPage }) {
    const numPagesToShow = 5;
    if (!startPage || startPage > pageCount - numPagesToShow)
      startPage = Math.max(1, pageCount - numPagesToShow + 1);

    const endPage = Math.min(pageCount, startPage + numPagesToShow - 1);

    const pages = [];
    for (let num = startPage; num <= endPage; num++) {
      const currentPage = num;
      pages.push(
        <li key={num} className="page-item">
          <a
            className={
              "btn btn-link text-white " +
              (currentPage == selectedPage ? " text-bold" : "")
            }
            onClick={() => handlePageClick(currentPage)}
          >
            {currentPage}
          </a>
        </li>
      );
    }

    if (startPage > 1) {
      pages.unshift(
        <button
          key={"first"}
          onClick={() => handlePageClick(1)}
          className="btn text-white"
        >
          ...
        </button>
      );
    }

    if (endPage < pageCount) {
      pages.push(
        <button
          key={"..."}
          onClick={() => handlePageClick(pageCount)}
          className="btn text-white"
        >
          ...
        </button>
      );
    }

    return (
      <ul className="pagination justify-content-center align-items-center">
        <li key={"start"} className="page-item">
          <a
            onClick={() => handlePreviousPage()}
            className="btn btn-link text-white"
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        <div className="d-flex">{pages}</div>
        <li key={"last"} className="page-item">
          <a
            onClick={() => handleNextPage()}
            className="btn btn-link text-white"
            aria-label="Next"
          >
            <span className="sr-only">Next</span>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    );
  }

  const searchEntires = () => {
    const fetchParams = {
      _limit: 30,
      _start: (selectedPage - 1) * 30,
      _q: searchTerm,
      _sort: "diaryDate:DESC"
    };
    const qs = require("qs");
    let query = {
      filters: {}
    };
    if (selectedYear) {
      query.filters.created_at = {
        $gte: new Date(selectedYear, 0, 1).toDateString()
      };
    }
    query = qs.stringify(query, {
      encodeValuesOnly: true // prettify URL
    });

    setLoading(true);
    axios
      .get(`https://mohabamroo.com/api/shredden-pieces/search?${query}`, {
        params: fetchParams
      })
      .then((res) => {
        setLoading(false);
        setEntries(res.data.pieces);
        setCount(res.data.count);
        let pages = [];
        let i = 1;
        let pagesCount = res.data.count / 30;
        while (i <= pagesCount) {
          pages.push(i);
          i++;
        }
        setPagesArr(pages);
        console.log("EntryList -> res", entries);
      });
  };

  const handlePageClick = (page) => {
    setPage(page);
  };

  const handleNextPage = () => {
    if (selectedPage < pagesArr.length) setPage(selectedPage + 1);
  };
  const handlePreviousPage = () => {
    if (selectedPage > 1) setPage(selectedPage - 1);
  };
  useEffect(() => {
    searchEntires();
  }, [selectedPage, searchTerm, selectedYear, selectedLang]);

  return (
    <>
      <div className="container px-4 px-lg-5">
        <div className="row pt-4 gx-4 gx-lg-5 justify-content-center">
          {/* Actions filter */}
          <div className="row font-16 d-flex justify-content-between">
            <div className="col-xs-12 col-lg-6 text-left">
              <button
                onClick={() => setLang("ar")}
                className={
                  "btn btn-action text-white droid-arabic-kufi" +
                  (selectedLang == "ar" ? " active" : "")
                }
              >
                العربية
              </button>
              <button
                onClick={() => setLang("en")}
                className={
                  "btn btn-action text-white" +
                  (selectedLang == "en" ? " active" : "")
                }
              >
                english
              </button>
            </div>
            <div className="d-none col col-lg-8 col-xs-12 d-flex flex-wrap justify-content-center">
              {years.map((year) => (
                <button
                  onClick={() => setSelectedYear(year)}
                  className="btn btn-action text-white"
                >
                  {year}
                </button>
              ))}
            </div>
          </div>
          {/* Search Row */}
          <div className="search-row form-group d-flex">
            <input
              onChange={(e) => setTerm(e.target.value)}
              className="form-control"
            />
            <button
              onClick={() => searchEntires(searchTerm)}
              className="btn text-white flex-1"
            >
              <i className="fa fa-search mr-2" aria-hidden="true"></i>
              <span className="ml-2"> Search</span>
            </button>
          </div>

          {/* Pagination Array */}
          <div className="col-12">
            <nav
              className="overflow-scroll"
              aria-label="Page navigation example"
            >
              <PaginatedDisplay
                startPage={selectedPage}
                pageCount={pagesArr.length}
              />
            </nav>
          </div>

          {loading && (
            <div className="d-flex justify-content-center container text-center margin-auto">
              <div className="loader"></div>
            </div>
          )}
          <div
            className={
              "col-md-12 col-lg-10 col-xl-8" + (loading ? "d-none" : "")
            }
          >
            {/* Post Preview */}

            <div className="entries">
              {filteredEntries
                .filter(
                  (entry) =>
                    selectedLang == "all" ||
                    (selectedLang == "ar" && isArabic(entry.title)) ||
                    (selectedLang == "en" && !isArabic(entry.title))
                )
                .map((entry) => {
                  return <Entry key={entry.id} entry={entry} moods={moods} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
